<template>
  <PageSection
    v-loading="isLoading"
    class="BrandSettings"
    :page-tooltip="$t('pages.settings.brand.brand.sidebar_description')"
    :page-title="brandForEdit.id ? $t('pages.settings.brand.brand.sidebar_title') : $t('pages.settings.brand.create.sidebar_title')"
  >
    <BrandManagement
      :brand="brandForEdit"
      @update="handleBrandCreated"
      @delete="handleBrandDelete"
    />
  </PageSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { NoBrandsError } from '@hypefactors/shared/js/errors/NoBrandsError.js'

import { brandFactory } from '@/factories/brand.js'
import PageSection from '@/components/core/PageSection.vue'
import BrandManagement from '@/pages/settings/brand/BrandManagement.vue'

export default {
  components: {
    PageSection,
    BrandManagement
  },

  data () {
    return {
      isLoading: false,
      brandForEdit: brandFactory()
    }
  },

  computed: {
    ...mapGetters(['activeBrand']),

    isNewBrand () {
      return this.$route.name === 'settings.brand.create'
    }
  },

  watch: {
    activeBrandId () {
      if (this.isNewBrand) {
        this.$router.push({ name: 'settings.brand.brand' })
      }

      this.brandForEdit = this.activeBrand
    },
    '$route': 'hydratePage'
  },

  mounted () {
    this.hydratePage()
  },

  methods: {
    ...mapActions(['fetchUserBrandsRequest', 'setActiveBrand', 'fetchUserBrandPermissions']),

    async handleBrandCreated (brand) {
      this.isLoading = true

      await this.fetchBrandData(brand)

      this.isLoading = false
    },

    async fetchBrandData (brand) {
      await this.fetchUserBrandsRequest()
        .catch(error => this.$displayRequestError(error, this.$t('errors.cannot_fetch_user_brands')))

      if (this.activeBrandId === brand.id) {
        return
      }

      await this.$router.push({ name: 'settings.brand.brand' })

      await this.setActiveBrand(brand.id)

      this.brandForEdit = this.activeBrand
    },

    hydratePage () {
      if (this.isNewBrand) {
        this.$confirm(this.$t('pages.settings.brand.create.confirm_create_brand'), {
          showCancelButton: false
        }).catch(() => {})

        this.brandForEdit = brandFactory()
      } else {
        this.brandForEdit = this.activeBrand
      }
    },

    async handleBrandDelete () {
      try {
        await this.fetchUserBrandsRequest()

        await this.fetchUserBrandPermissions()
      } catch (err) {
        if (err instanceof NoBrandsError) {
          await this.$router.push({ name: 'dashboard.dashboard' })
        } else {
          this.$displayRequestError(err)
        }
      }
    }
  }
}
</script>
