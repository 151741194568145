import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'

export const brandFactory = (override = {}) => ({
  name: '',
  industry: '',
  country: '',
  currency_code: '',
  users: [],
  logo: croppieDataFactory(),
  members: {
    data: []
  },
  user_followed_streams_count: 0,
  streams_count: 1,
  auto_follow_newly_created_streams: false,
  ...override
})
