<template>
  <div v-loading="form.isLoading || isLoading" class="BrandManagement">
    <div class="columns">
      <div class="column is-flex is-aligned-middle is-aligned-center">
        <CroppieWrapper
          ref="logo"
          v-model="form.logo"
          :settings="croppieSettings"
          :crop-options="croppieSettings.cropOptions"
          :min-width="120"
          :min-height="120"
          :enforce-boundary="false"
          is-circle
          text-size="7"
          entity="BrandsLogo"
        >
          {{ $t('components.brand_management.upload_brand_logo') }}
        </CroppieWrapper>
      </div>

      <div class="column">
        <FormField
          :validator="$v.form.name"
          :label="$t('components.brand_management.name_brand_organisation')"
        >
          <input
            v-model="form.name"
            :placeholder="$t('general.brand_name')"
            class="input"
            type="text"
            name="name"
            @input="$v.form.name.$touch()"
          >
        </FormField>

        <FormField
          :validator="$v.form.country"
          :label="$t('forms.country')"
        >
          <CountryPicker
            v-model="form.country"
            popper-class="SettingsBrands__countryModal"
            value-key="id"
            @change="updateCurrencyFromCountry"
          />
        </FormField>

        <FormField
          :validator="$v.form.currency"
          :label="$t('general.currency')"
        >
          <CurrencyPicker
            v-model="form.currency"
            :placeholder="$t('forms.choose_currency')"
            popper-class="SettingsBrands__currencyModal"
          />
        </FormField>
      </div>
    </div>

    <div class="is-flex m-b-m">
      <DeleteBrandButton
        v-if="!isNewBrand"
        :brand-id="brand.id"
        :brand-name="brand.name"
        @delete="$emit('delete')"
      />
      <VButton class="m-l-a is-primary" @click="saveBrand">
        {{ $t('forms.save_brand') }}
      </VButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { croppieDataFactory, croppieDefaultSettings, mergeCroppieData } from '@hypefactors/shared/js/factories/croppie.js'
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService.js'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import CroppieWrapper from '@hypefactors/shared/js/components/core/CroppieWrapper.vue'
import CountryPicker from '@hypefactors/shared/js/components/core/CountryPicker.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'

import CurrencyPicker from '@/components/core/CurrencyPicker.vue'
import DeleteBrandButton from '@/pages/settings/brand/DeleteBrandButton.vue'
import Form from '@/services/forms/Form.js'

const brandForm = () => new Form({
  name: { value: '', rules: { required } },
  country: { value: '', rules: { required } },
  currency: { value: '', rules: { required } },
  logo: { value: croppieDataFactory() }
})

export default {
  components: {
    CroppieWrapper,
    FormField,
    CountryPicker,
    CurrencyPicker,
    DeleteBrandButton,
    VButton
  },

  props: {
    brand: {
      type: Object,
      required: true
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      isLoading: false,
      cancelToken: null,
      form: brandForm(),
      croppieSettings: croppieDefaultSettings(120, 120)
    }
  },

  computed: {
    ...mapGetters(['firstOrganisation']),

    isNewBrand () {
      return !this.brand.id
    }
  },

  watch: {
    brand: {
      immediate: true,
      handler: 'mergeBrand'
    }
  },

  methods: {
    async mergeBrand (brand) {
      this.$v.form.$reset()

      this.form.reset()

      this.form.merge({
        ...brand,
        currency: this.brand.currency_code,
        country: this.$safeGet(brand, 'country.data', null),
        logo: mergeCroppieData(brand.logo)
      })

      this.cancelToken && this.cancelToken.cancel()

      this.newUsers = []
    },

    updateCurrencyFromCountry (country) {
      const currency = country.currency_code

      this.form.currency = currency

      this.$notify.info(this.$t('info.updated_currency', { currency, country: country.name }))
    },

    async saveBrand () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.isLoading = true

      this.cancelToken = this.$api.cancelToken()

      try {
        await this.$refs.logo.uploadImage({ cancelToken: this.cancelToken.token })

        this.form.setPayloadTransformer(data => {
          return {
            ...data,
            organisation: this.firstOrganisation.id,
            country: data.country.iso_alpha2
          }
        })

        const options = {
          cancelToken: this.cancelToken.token
        }

        const action = (payload) => this.isNewBrand
          ? BrandApiService.create(payload, options)
          : BrandApiService.update(this.brand.id, payload, options)

        const brandResponse = await this.form.submit(action)

        this.$emit('update', brandResponse.data)
      } catch (e) {
        if (this.$api.isCancelToken(e)) {
          return
        }

        this.$displayRequestError(e, this.$t('errors.cannot_save_brand'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
